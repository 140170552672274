import React from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import icetop from "../Assets/top.png";
import Lottie from "lottie-react";
import corptop from "../Jsonfile/fish(left to right).json";
import vehicless from "../Jsonfile/vehicless.json";

import submarine from "../Assets/submarine.png";

export default function Section3() {

    return (
        <>
            <div>

                <Lottie className="croplasttop" animationData={corptop} loop={true} />
                {/* <Lottie className="vehicless" animationData={vehicless} loop={true} /> */}
                <img src={submarine} className="vehicless" />
                <img src={icetop} className="icetop" />
                
                <div className="section2 section5 imagegif">
                    <div className="container">
                        <div className="height">
                            <div className="row">
                                <h3 className="future">Help build the future.</h3>
                                <p className="mb-4">We extend a warm invitation to all developers, innovators, and visionaries to delve into the intricate frameworks that power.
                                    Our doors are open for you to unravel the possibilities, tap into the potentials, and reshape the future of decentralized finance.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}      