import React from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { BsTwitter,BsGithub,BsYoutube } from 'react-icons/bs'; 
import { RiTwitterXFill } from 'react-icons/ri';

export default function Footer() {

    return (
      <>
      
        <div className="Footer pb-sm-4">
          <div className="container"> 
            <div className="row pt-1 pb-2">
            <div className="col-sm-6 col-md-7">
                 <h5>Need Help?</h5>       
                 <p>If you need help or have any questions, contact us on one of our social channels</p>
            </div>
            <div className="col-sm-6 col-md-5">
                 <h5>Contact Support</h5>       
                 <p className="mb-1">Join the Discord community and ask away!</p>
                 <a href="https://twitter.com/" target="_blank"><RiTwitterXFill/></a> 
                 <a href="https://github.com/" target="_blank" className="ms-2"><BsGithub/></a> 
                 <a href="https://www.youtube.com/" target="_blank" className="ms-2"><BsYoutube/></a>
            </div>
            </div>   
            </div>
        </div>
      
      </>

);
}