import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Button } from "react-bootstrap";
import { FiArrowUpRight } from "react-icons/fi";
import Card from "react-bootstrap/Card";
import UnlockClock from "../../Assets/unlock_clock.png";
import lockClock from "../../Assets/lock_clock.png";
import InformationLogo from "../../Assets/information.png";
import { FiFilter } from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import WorldLogo from "../../Assets/world-logo.png";
import Table from "react-bootstrap/Table";
import CoinLight from "../../Assets/ph_coins-light.png";
import EthIcon from "../../Assets/eth.png";
import DaIcon from "../../Assets/multi-collateral-dai-dai-logo.png";
import EthSvgrepo from "../../Assets/eth-svgrepo.png";
import DownArrow from "../../Assets/down_arrow.png";
import Anchor1 from "../../Assets/anchor1.png";
import Anchor2 from "../../Assets/anchor2.png";
import { Link } from "react-router-dom";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import Footer from "../Footer";

function Pools() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  const PoolsTable = [
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "BAL 80%",
        },
        {
          val: "WETH 80%",
        },
      ],
      poolvalue: "$127,167,635",
      volumn: "$108,890",
      apr: [
        {
          val: "0.16%",
          logo: Anchor1,
        },
      ],
      type: "percentage",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "rETH",
        },
        {
          val: "WETH",
        },
      ],
      poolvalue: "$45,189,636",
      volumn: "$53,507",
      apr: [
        {
          val: "2.70%",
          logo: Anchor2,
        },
      ],
      type: "text",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "WETH",
        },
      ],
      poolvalue: "$22,522,768",
      volumn: "$13,535,555",
      apr: [
        {
          val: "3.07%",
          logo: Anchor2,
        },
      ],
      type: "text",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "sfrxETH ",
        },
        {
          val: "sfrxETH ",
        },
      ],
      poolvalue: "$22,522,768",
      volumn: "$13,535,555",
      apr: [
        {
          val: "3.07%",
          logo: Anchor2,
        },
      ],
      type: "text",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "BAL 80%",
        },
        {
          val: "WETH 80%",
        },
      ],
      poolvalue: "$127,167,635",
      volumn: "$108,890",
      apr: [
        {
          val: "0.16%",
          logo: Anchor1,
        },
      ],
      type: "percentage",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "rETH",
        },
        {
          val: "WETH",
        },
      ],
      poolvalue: "$45,189,636",
      volumn: "$53,507",
      apr: [
        {
          val: "2.70%",
          logo: Anchor2,
        },
      ],
      type: "text",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "WETH",
        },
      ],
      poolvalue: "$22,522,768",
      volumn: "$13,535,555",
      apr: [
        {
          val: "3.07%",
          logo: Anchor2,
        },
      ],
      type: "text",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "sfrxETH ",
        },
        {
          val: "sfrxETH ",
        },
      ],
      poolvalue: "$22,522,768",
      volumn: "$13,535,555",
      apr: [
        {
          val: "3.07%",
          logo: Anchor2,
        },
      ],
      type: "text",
    },
  ];

  return (
    <div className="pools_page innerheader">
      <Header />
      <div className="top">
        <div className="head_sec py-5">
          <div className="text-center">
            <h3 className="head">Defi Liquidity Pools</h3>
            <p>Built on Balancer protocol</p>
          </div>
        </div>

        <div className="content_sec py-5">
          <div className="container">
            <div className="pools_eligible">
              <h3 className="heading pb-4">Ethereum pools</h3>
              <div className="table_filter_sec row">
                <div className="sec_fst col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 mb-3 mb-sm-3 mb-md-0 mb-lg-0 mb-xl-0">
                  <input
                    type="text"
                    id="myInput"
                    placeholder="Filter by token"
                    title="Filter by token"
                  />
                  <Dropdown className="ps-2 ps-sm-0 ps-md-0 ps-lg-0 ps-xl-0">
                    <Dropdown.Toggle className="more_filter_drop">
                      <FiFilter /> More filters
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="filtermenu py-3 px-3">
                      <h6 className="fw-700 textclr">Pool Value</h6>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                          <label class="form-check-label" for="flexCheckDefault">
                            Weighted
                          </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
                          <label class="form-check-label" for="flexCheckDefault">
                            Stable
                          </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2"/>
                          <label class="form-check-label" for="flexCheckDefault">
                            CLP
                          </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault3"/>
                          <label class="form-check-label" for="flexCheckDefault">
                            LBP
                          </label>
                      </div>
                      <h6 className="fw-700 mt-3 textclr">Pool attributes</h6>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault4"/>
                          <label class="form-check-label" for="flexCheckDefault">
                            New
                          </label>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="sec_snd col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <Link to="/createpool"><Button className="create_pool_btn">Create a pool</Button></Link>
                </div>
              </div>
              <div className="common_table_style mt-4">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="coinlight_th">
                        <div className="coinlight_img">
                          <img
                            src={CoinLight}
                            alt="information_logo"
                            className="img-fluid info_icon"
                          />
                        </div>
                      </th>
                      <th>Composition</th>
                      <th className="pool_value">
                        Pool value <BsArrowDown />
                        {/* <BsArrowUp /> */}
                      </th>

                      <th className="volumn">Volume (24h)</th>
                      <th className="apr">APR</th>
                    </tr>
                  </thead>
                  <tbody className="t_body">
                    {PoolsTable.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td className="coinlight_td">
                              <div className="pools_icons_collapse">
                                <div className="position-relative">
                                  {item.DaLogo.map((itemlogo) => {
                                    return (
                                      <>
                                        <img
                                          src={itemlogo.Icon}
                                          alt="EthIcon"
                                          className="img-fluid"
                                        />
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="composition_sec">
                                {item.composition.map((itemm) => {
                                  return (
                                    <>
                                      <span
                                        className={
                                          item.type == "percentage"
                                            ? "high_radius"
                                            : "low_radius"
                                        }
                                      >
                                        {itemm.val}
                                      </span>
                                    </>
                                  );
                                })}
                              </div>
                            </td>
                            <td>{item.poolvalue}</td>
                            <td>{item.volumn}</td>
                            <td>
                              {item.apr.map((itemapr) => {
                                return (
                                  <>
                                    {itemapr.val}
                                    <img
                                      src={itemapr.logo}
                                      alt="down_arrow"
                                      className="img-fluid info_icon ps-2"
                                    />
                                  </>
                                );
                              })}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pools;
