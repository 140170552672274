import React from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import Partner1 from "../Assets/partner1.png";
import Partner2 from "../Assets/partner2.png";
import Partner3 from "../Assets/partner3.png";
import Partner4 from "../Assets/partner4.png";
import Partner5 from "../Assets/partner5.png";
import Partner6 from "../Assets/partner6.png";
import Partner7 from "../Assets/partner7.png";
import Lottie from "lottie-react";
import bubble from "../Jsonfile/bubble.json";

import corptop from "../Jsonfile/fish(left to right).json";
import corpdown from "../Jsonfile/fish(right to left).json";
// import corpbelow from "../Jsonfile/corp_below.json";

import manta from "../Jsonfile/manta (1).json";
import shark from "../Jsonfile/shark.json";
import snorkle from "../Jsonfile/snorkle.json";
import sunrace from "../Jsonfile/rays.json";
import glow from "../Assets/glow.webp";


export default function Section2() {

  return (
    <>

      <div>
        <Lottie className="croptop" animationData={corptop} loop={true} />
        <Lottie className="cropdown" animationData={corpdown} loop={true} />
        <Lottie className="cropbelow" animationData={corptop} loop={true} />

        <Lottie className="shark" animationData={shark} loop={true} />
        <Lottie className="manta" animationData={manta} loop={true} />
    
        <Lottie className="sunrace" animationData={sunrace} loop={true} />
        <img src={glow} className="img-fluid glow" />
        <div className="section2">

          <div className="container">
         
            <div className="height">
              <div className="row imagegif partnerimg justify-content-center">
                <Lottie className="snorkle" animationData={snorkle} loop={true} />
                <Lottie className="bubbless" animationData={bubble} loop={true} />
                <h3>1135 Days.</h3>
                <p className="mb-4">Ever since the inception of Phorcys, our journey has been enriched by the invaluable connections we've forged.
                  As we pave the way towards the Future of Finance, we want to express our deep gratitude to the incredible
                  community of friends and supporters who have accompanied us on this remarkable path</p>




                {/* <div class="logogrid">
              <img src={Partner1} className="img-fluid"/>
              <img src={Partner2} className="img-fluid"/>
              <img src={Partner3} className="img-fluid"/>
              <img src={Partner4} className="img-fluid"/>
              <img src={Partner5} className="img-fluid"/>
              <img src={Partner6} className="img-fluid"/>
              <img src={Partner7} className="img-fluid"/>
              </div>  */}

                <div className="col-6 col-md-3 col-sm-4 mt-3 mb-3">
                  <img src={Partner1} className="img-fluid" />
                </div>
                <div className="col-6 col-md-3 col-sm-4 mt-3 mb-3">
                  <img src={Partner2} className="img-fluid" />
                </div>
                <div className="col-6 col-md-3 col-sm-4 mt-3 mb-3">
                  <img src={Partner3} className="img-fluid" />
                </div>
                <div className="col-6 col-md-3 col-sm-4 mt-3 mb-3">
                  <img src={Partner4} className="img-fluid" />
                </div>
                <div className="col-6 col-md-3 col-sm-4 mt-3 mb-3">
                  <img src={Partner5} className="img-fluid" />
                </div>
                <div className="col-6 col-md-3 col-sm-4 mt-3 mb-3">
                  <img src={Partner6} className="img-fluid" />
                </div>
                <div className="col-6 col-md-3 col-sm-6 mt-3 mb-3">
                  <img src={Partner7} className="img-fluid" />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}      