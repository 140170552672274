import React, { useRef, useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import logos from "../Assets/phorcys_logo.png";
import { Link, NavLink } from "react-router-dom";

export default function Headermodal(props) {
    const [headermodal, setHeadermodal] = useState(true)
    return (
        <>

            <div>
                <Modal
                className="allmodals headmodal"
                    onHide={props.onDismiss}
                    show={headermodal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
                    <Modal.Body>
                        <Link to="/"><img className="headerlogo img-fluid mb-3" src={logos} alt="jack" /></Link>
                        <NavLink to="/" onClick={props.onDismiss}>Home</NavLink>
                        <NavLink to="/swap" onClick={props.onDismiss}>Swap</NavLink>
                        <NavLink to="/pools" onClick={props.onDismiss}>Pools</NavLink>
                        <NavLink to="/claim" onClick={props.onDismiss}>Claim</NavLink>
                        <NavLink to="/portfolio" onClick={props.onDismiss}>Portfolio</NavLink>
                        <NavLink to="/vephor" onClick={props.onDismiss}>vePHOR</NavLink>
                    </Modal.Body>
                </Modal>
            </div>

        </>
    );
}