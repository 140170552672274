import React, { useRef, useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import logos from "../Assets/phorcys_logo.png";
import menu from "../Assets/menu-bar.png";
import Headermodal from "../Components/headermodal";
import Walletmodal from "../Components/walletmodal";
import { BsWallet } from 'react-icons/bs';


export default function Header() {

    const [headermodal, setHeadermodal] = useState(false);
    const [Walletmodals, setWalletmodals] = useState(false)

    return (
      <>
       {headermodal && <Headermodal onDismiss={() => setHeadermodal(false)} />}
       {Walletmodals && <Walletmodal onDismiss={() => setWalletmodals(false)} />}

            <div className="desktopheader">
              <Navbar expand="lg">
                    <Container className="custom_container">
                        <Navbar.Brand href="/">
                            <img className="headerlogo img-fluid" src={logos} alt="jack" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <NavLink to="/swap">Swap</NavLink>
                                <NavLink to="/pools">Pools</NavLink>
                                <NavLink to="/claim">Claim</NavLink>
                                <NavLink to="/portfolio">Portfolio</NavLink>  
                                <NavLink to="/vephor">vePHOR</NavLink>                                                           
                            </Nav>
                            <Button className="searchbtn" onClick={() => setWalletmodals(true)} variant="light">Connect Wallet</Button>                        
                        </Navbar.Collapse>
                    </Container>
                </Navbar>   
                </div>

                <div className="mobileheader">
                    <div className="container">
                    <div className="d-flex justify-content-between pt-2 pb-2">
                    <Link to="/"><img className="headerlogo img-fluid" src={logos} alt="jack" /></Link>
                    <div>
                    <Button className="searchbtn me-2" onClick={() => setWalletmodals(true)} variant="light"><BsWallet/></Button> 
                    <img className="menubar img-fluid" onClick={() => setHeadermodal(true)} src={menu} alt="jack" />
                    </div>
                    </div>
                    </div>
                </div>
      
      </>

);
}