import React, { useEffect } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import Leaf from "../Assets/leaf.webp";
import Lottie from "lottie-react";
import whale from "../Jsonfile/whale.json";
import bubble from "../Jsonfile/bubble.json";

export default function Section3() {



    return (
        <>
            <div>

                {/* <div className="bodylist">
                <div class="containerss"><canvas style={{ width:"70%", height:"70%" }}  id="fishtank" />
                </div>
                </div> */}

                <Lottie className="whale" animationData={whale} loop={true} />
         <Lottie className="bubbleunder" animationData={bubble} loop={true} />
            <div className="section2 imagegif">
            <img src={Leaf} className="leaf"/>
                <div className="container">
                    <div className="height">
                        <div className="row">                      
                            <h3>Move assets across networks in seconds.</h3>
                            <p className="mb-4">We will always find you the best rate, no matter what network you're on,with no extra fees.</p>
                        </div>
                    </div>
                </div>
            </div>



            </div>
        </>
    );
}      