import React from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import Lottie from "lottie-react";

// import shipwreck from "../Jsonfile/bg_shipwreck.json";
// import diver from "../Jsonfile/diver.json";
import corptop from "../Jsonfile/fish(left to right).json";
import bubble from "../Jsonfile/bubble.json";

import divekani from "../Assets/divekanavukani.png";
import divemonkey from "../Assets/divemonkey.png";

import shipwreck from "../Assets/shipwreck.png";

import weed from "../Jsonfile/weed.json";
import bush from "../Jsonfile/bush.json";

export default function Section3() {

    return (
        <>
            <div>

            <Lottie className="bubbletreasure" animationData={bubble} loop={true} />
            <Lottie className="bubbletreasure1" animationData={bubble} loop={true} />
            <Lottie className="croptop" animationData={corptop} loop={true} />   

            {/* <Lottie className="diver" animationData={diver} loop={true} />
            <Lottie className="merm" animationData={merm} loop={true} />  */}

            <img src={divemonkey} className="diver"/>
            <img src={divekani} className="merm"/>

            <img src={shipwreck} className="shipwreck"/>

        <Lottie className="weedsec" animationData={weed} loop={true} />
        <Lottie className="weed1" animationData={weed} loop={true} />

        <Lottie className="bushsec" animationData={bush} loop={true} />
        <Lottie className="bushtop" animationData={bush} loop={true} />
            
            {/* <Lottie className="shipwreck" animationData={shipwreck} loop={true} /> */}
                <div className="section2 section5 imagegif">
                    <div className="container">
                        <div className="height">
                            <div className="row">
                                <h3>Earn passive income with your coins.</h3>
                                <p className="mb-4">With multiple ways to passively earn yield on your coins, you can choose your own yield
                                    stack and make your money work for you, all in the background, 24/7.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}      