import React from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import Lottie from "lottie-react";
// import corel from "../Jsonfile/corel.json";
import bubble from "../Jsonfile/bubble.json";
import backdrop from "../Assets/backdrop.png"
import frontdrop from "../Assets/front.png";

import waveplant from "../Jsonfile/wave plant.json";
import weed from "../Jsonfile/weed.json";
import bush from "../Jsonfile/bush.json";

import corel3 from "../Assets/corel3.png";

import tagfish from "../Assets/tang.png";
import anglefish from "../Assets/angle.png";

export default function Section3() {

  return (
    <>
      <div>
        
      <div className="bottom_fish">
        {/* <Lottie className="corel" animationData={corel} loop={true} /> */}
        <img src={backdrop} className="backdrop img-fluid"/>
        <img src={frontdrop} className="frontdrop img-fluid"/>
        
       <div className="tagfishdiv"><img src={tagfish} className="tagfish" /></div>
        {/* <img src={tagfish} className="tagfish1" /> */}
        <div className="tagfishdiv"><img src={anglefish} className="anglefish" /></div>
        <div className="tagfishdiv"><img src={anglefish} className="anglefish anglefish2" /></div> 
        <div className="tagfishdiv"><img src={anglefish} className="anglefish anglefish1" /></div> 

        <img src={corel3} className="corel3"/>
      
        <Lottie className="waveplant" animationData={waveplant} loop={true} />
        <Lottie className="weed" animationData={weed} loop={true} />
        <Lottie className="weed1" animationData={weed} loop={true} />
        <Lottie className="bush" animationData={bush} loop={true} />
        <Lottie className="bush1" animationData={bush} loop={true} />

        <Lottie className="bubble" animationData={bubble} loop={true} />
        <Lottie className="bubble1" animationData={bubble} loop={true} />
        <Lottie className="bubble2" animationData={bubble} loop={true} />
        </div>

        <div className="section2">

          <div className="container">
            <div className="height">
              <div className="row imagegif">
                <h3>Your keys, your coins</h3>
                <p className="mb-4">Own your own crypto, just like cash in your wallet.
                  Fully decentralized & self custody of your funds means your money in your wallet, as it should be.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}      