import React, { useRef, useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';



export default function Loaders(props) {

    return (
        <>


            <div className="loaderback">


                <div class="loader">
                    <h1>Phorcys</h1>
                </div>





            </div>

        </>

    );
}