import React, { useEffect, useState } from "react";
import Header from "../Header";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InformationLogo from "../../Assets/information.png";
import Polygon from "../../Assets/polygon.png";
import Arbitrum from "../../Assets/arbitrum.png";
import Footer from "../Footer";

function Claim() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  const PhorIncentives = [
    {
      pools: "$127,167,635",
      amount: "#0.000",
      value: "$108,890",
    },
    {
      pools: "$45,189,636",
      amount: "#0.001",
      value: "$$53,507",
    },
    {
      pools: "$22,522,768",
      amount: "#0.002",
      value: "$13,535,555",
    },
    {
      pools: "$22,522,768",
      amount: "#0.003",
      value: "$13,535,555",
    },
  ];

  const VephorIncentives = [
    {
      pools: "$127,167,635",
      amount: "#0.000",
      value: "$108,890",
    },
    {
      pools: "$45,189,636",
      amount: "#0.001",
      value: "$$53,507",
    },
    {
      pools: "$22,522,768",
      amount: "#0.002",
      value: "$13,535,555",
    },
    {
      pools: "$22,522,768",
      amount: "#0.003",
      value: "$13,535,555",
    },
  ];

  return (
    <div className="claim_page innerheader">
      <Header />
      <div className="top">
        <div className="head_sec">
          <div className="text-center container">
            <h3 className="head">Claim liquidity incentives</h3>
            <p className="mb-0">
              Balancer Protocol liquidity incentives are directed to pools by
              vePHOR voters. Stake in these pools to earn incentives. Boost with
              vePHOR for up to 2.5x extra.
            </p>
          </div>
        </div>
        <div className="content_sec py-5">
          <div className="container">
            <h3 className="heading">Ethereum liquidity incentives</h3>
            <div className="mt-4 mt-sm-3 mt-md-3 mt-lg-5 mt-xl-5">
              <h6 className="sub_heading mb-4">
                PHOR incentives
                <img
                  src={InformationLogo}
                  alt="information_logo"
                  className="img-fluid info_icon ps-2"
                />
              </h6>
              <div className="common_table_style">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Pools</th>
                      <th>Amount</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {PhorIncentives.map((item) => {
                    return (
                      <>
                        <tr>
                          <td>{item.pools}</td>
                          <td>{item.amount}</td>
                          <td>{item.value}</td>
                        </tr>
                      </>
                    );
                  })}

                    {/* <tr>
                      <td colSpan={3}>
                        No PHOR incentives to claim from staking
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="mt-4 mt-sm-3 mt-md-3 mt-lg-5 mt-xl-5">
              <h6 className="sub_heading mb-4">
                Protocol revenue & vePHOR incentives
                <img
                  src={InformationLogo}
                  alt="information_logo"
                  className="img-fluid info_icon ps-2"
                />
              </h6>
              <div className="common_table_style">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Token</th>
                      <th>Amount</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {VephorIncentives.map((item) => {
                    return (
                      <>
                        <tr>
                          <td>{item.pools}</td>
                          <td>{item.amount}</td>
                          <td>{item.value}</td>
                        </tr>
                      </>
                    );
                  })} */}
                    <tr>
                      <td colSpan={3}>Nothing to claim from holding vePHOR</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="mt-3 mt-sm-3 mt-md-3 mt-lg-5 mt-xl-5">
              <h6 className="sub_heading mb-4">Other token incentives</h6>
              <div className="claim_incentive">No claimable incentives</div>
            </div>
            <div className="mt-4 mt-sm-3 mt-md-3 mt-lg-5 mt-xl-5">
              <h6 className="sub_heading mb-4">Incentives on other networks</h6>
              <div className="networks">
                <Button className="common_inner_btn">
                  <img src={Polygon} alt="polygon" className="img-fluid me-2" />
                  Claim On Polygon
                </Button>
                <Button className="common_inner_btn">
                  <img src={Arbitrum} alt="polygon" className="img-fluid me-2" />
                  Claim On Arbitrum
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Claim;
