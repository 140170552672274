import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { MdSettings } from 'react-icons/md';
import { BiSearch } from 'react-icons/bi';
import { Button, Container, Row, Dropdown } from 'react-bootstrap';
import Eth from "../../Assets/eth.png";
import Dai from "../../Assets/dai.png";
import Usdc from "../../Assets/usdc.svg";
import Bal from "../../Assets/Bal.svg";
import Snx from "../../Assets/snx.svg";
import swapimg from "../../Assets/swapimg.png";
import { FaGasPump } from 'react-icons/fa';
import Apexx from "../../Components/linechart";
import Walletmodal from "../../Components/walletmodal";

export default function SwapPage() {

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, []);

    let regex = /^[0-9]+$/;
    const [value, setValue] = useState(0.00);
    const [value1, setValue1] = useState(0.00);

    const [Walletmodals, setWalletmodals] = useState(false)


    const [coinlist, setCoinlist] = useState([
        {
            "id": 1, "image": Eth, "coinname": "WETH", "cointext": "wrapped Ether"
        },
        {
            "id": 2, "image": Dai, "coinname": "DAI", "cointext": "Dai Stablecoin"
        },
        {
            "id": 3, "image": Usdc, "coinname": "USDC", "cointext": "USD Coin"
        },
        {
            "id": 4, "image": Bal, "coinname": "BAL", "cointext": "Balancer"
        },
        {
            "id": 5, "image": Snx, "coinname": "SNX", "cointext": "Synthetix Network Token"
        },
    ]);

    const [interchange, setInterchange] = useState(
        {
            "id": 1, "image": Eth, "coinname": "WETH", "cointext": "wrapped Ether"
        }
    );
    const [intertwochange, setIntertwochange] = useState(
        {
            "id": 2, "image": Dai, "coinname": "DAI", "cointext": "Dai Stablecoin"
        },
    );

    const onswap = () => {
        var changea = interchange
        var changeb = intertwochange
        setInterchange(changeb)
        setIntertwochange(changea)
    }


    return (
        <>
            {Walletmodals && <Walletmodal onDismiss={() => setWalletmodals(false)} />}
            <div className="swappage innerheader">

                <div className="headers"> <Header /></div>

                <div className="swaps">
                    <div className="container">
                        <div className="swapsflex">
                            <div className="row">
                                <div className="col-md-7 col-lg-8 mt-3">
                                    <div className="swapborder minborder">
                                        <div className="d-flex justify-content-between align-items-center"><p className="swaptext">Swap</p>
                                            <p className="settingdrop">
                                                <Dropdown>
                                                    <Dropdown.Toggle className="setting-btn" variant="success" id="dropdown-basic">
                                                        <MdSettings className="settings" /></Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <h6 className="fw-600 mb-3">Slippage tolerance</h6>
                                                        <div class="d-flex justify-content-between">
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">0.5%</button>
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">1.0%</button>
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">2.0%</button>
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">2.5%</button>
                                                        </div>
                                                        <h6 className="fw-600 mt-4 mb-2">Transaction type</h6>
                                                        <div class="d-flex">
                                                            <button class="btn btn-outline-secondary btn-sm me-3" type="button">Legacy</button>
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">Legacy</button>
                                                        </div>
                                                        <h6 className="fw-600 mt-4 mb-2">Use signature</h6>

                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                               
                                                        </div>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </p>
                                        </div>

                                        <div className="swapinborder commonborder d-flex justify-content-between">
                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle className="drop" variant="success" id="dropdown-basic">
                                                        <img src={interchange.image} className="ethimg me-1" alt="eth" />
                                                        {interchange.coinname}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <h6 className="fw-600 mb-3">Token Search</h6>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text" id="basic-addon1"><BiSearch className="search" /></span>
                                                            </div>
                                                            <input type="search" class="form-control" placeholder="Search by name, symbol or address" aria-label="Username" aria-describedby="basic-addon1" />
                                                        </div>
                                                        <div className="menuheight">
                                                            {coinlist.map((e, i) =>
                                                                <Dropdown.Item className={e.id == interchange.id ? "hideclass" : ""} onClick={() => { setInterchange(e) }}>
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={e.image} className="ethimg me-3" alt="eth" />
                                                                        <div>
                                                                            <p className="mb-0 coinname">{e.coinname}</p>
                                                                            <p className="mb-0 coinsubtext">{e.cointext}</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            )}
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="ko">
                                                <input type="text" className="form-control" value={value} onChange={(e) => { if (e.target.value == '' || regex.test(e.target.value)) { setValue(e.target.value) } }} />
                                            </div>
                                        </div>

                                        <div className="mt-3 mb-3 text-center"><img src={swapimg} onClick={() => onswap()} className="swapimg" alt="eth" /></div>

                                        <div className="swapinborder commonborder d-flex justify-content-between">
                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle className="drop" variant="success" id="dropdown-basic">
                                                        <img src={intertwochange.image} className="ethimg me-1" alt="eth" /> {intertwochange.coinname}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <h6 className="fw-600 mb-3">Token Search</h6>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text" id="basic-addon1"><BiSearch className="search" /></span>
                                                            </div>
                                                            <input type="search" class="form-control" placeholder="Search by name, symbol or address" aria-label="Username" aria-describedby="basic-addon1" />
                                                        </div>
                                                        <div className="menuheight">
                                                            {coinlist.map((e, i) =>
                                                                <Dropdown.Item disabled={e.id == interchange.id} onClick={() => setIntertwochange(e)}>
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={e.image} className="ethimg me-3" alt="eth" />
                                                                        <div>
                                                                            <p className="mb-0 coinname">{e.coinname}</p>
                                                                            <p className="mb-0 coinsubtext">{e.cointext}</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            )}
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="ko">
                                                <input type="text" className="form-control" value={value1} onChange={(e) => { if (e.target.value == '' || regex.test(e.target.value)) { setValue1(e.target.value) } }} />
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <Button className="searchbtn mt-3" variant="light">Preview</Button>
                                            <p className="gas mt-3"><FaGasPump /> Gas swap—required when swapping from ETH</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-lg-4 mt-3">
                                    <div className="swaplightborder">
                                        <div className="d-flex justify-content-between bb"> <p className="mb-0">My Wallet</p> <p className="mb-0">-ETH</p></div>
                                        <h6 className="walletclr mt-2 mb-0" style={{ cursor: "pointer" }} onClick={() => setWalletmodals(true)}>Connect your wallet</h6>
                                    </div>
                                    <div className="swaplightborder mt-3">
                                        <p className="mb-2">DAI/ETH</p>
                                        <h5 className="no">1,558.59</h5>
                                        <p className="redno mb-0">-1.6%</p>
                                        <Apexx />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                   
                </div>
                <div className="mt-4"><Footer /></div>


            </div>


        </>

    );
}