import React, { useEffect, useState } from "react";
import Header from "../Header";
import detail from "../../Assets/detail_light.png";
import Bal from "../../Assets/pools/Bal.svg";
import Mkr from "../../Assets/pools/mkr.png";
import Usdc from "../../Assets/pools/usdc.png";
import Wbtc from "../../Assets/pools/wbtc.png";
import Weth from "../../Assets/pools/weth.png";
import Notenough from "../../Assets/pools/not_enough_data.png";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { FiArrowUpRight } from "react-icons/fi";
import { BsArrowDown } from "react-icons/bs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BiPlus } from "react-icons/bi";
import { Button } from "react-bootstrap";
import Footer from "../Footer";

function WeightedPool() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  const Pools = [
    {
      token: [
        {
          icon: Bal,
          name: "BAl",
        },
      ],
      weight: "50.00%",
      balance: "4,803.074",
      value: "$99,952",
    },
    {
      token: [
        {
          icon: Weth,
          name: "WETH",
        },
      ],
      weight: "15%",
      balance: "7.0674",
      value: "$29,992",
    },
    {
      token: [
        {
          icon: Mkr,
          name: "MKR",
        },
      ],
      weight: "15%",
      balance: "10",
      value: "$29,768",
    },
    {
      token: [
        {
          icon: Usdc,
          name: "USDC",
        },
      ],
      weight: "5%",
      balance: "10,000",
      value: "$10,000",
    },
    {
      token: [
        {
          icon: Wbtc,
          name: "WBTC",
        },
      ],
      weight: "15%",
      balance: "0.529",
      value: "$29,985",
    },
  ];
  const PoolTransaction = [
    {
      action: "",
      details: [
        {
          icon: Wbtc,
          val: "0.529",
        },
        {
          icon: Bal,
          val: "4,803.074",
        },
        {
          icon: Mkr,
          val: "10",
        },
        {
          icon: Usdc,
          val: "10.000",
        },
        {
          icon: Weth,
          val: "7.0674",
        },
      ],
      value: "$199.70K",
      time: "Less than a minute ago",
    },
  ];

  const MypoolBalance = [
    {
      icon: Wbtc,
      percentage: "15.00%",
      name: "WBTC",
      initialweight: "15:02%",
      val: "0.529",
      amount: "$30,000",
    },
    {
      icon: Bal,
      percentage: "50%",
      name: "BAL",
      initialweight: "15:02%",
      val: "4,803.074",
      amount: "$99,952",
    },
    {
      icon: Mkr,
      percentage: "15.00%",
      name: "MKR",
      initialweight: "15:02%",
      val: "10",
      amount: "$29,785",
    },
    {
      icon: Usdc,
      percentage: "5.00%",
      name: "USDC",
      initialweight: "5:01%",
      val: "10,000",
      amount: "$10,000",
    },
    {
      icon: Weth,
      percentage: "15.00%",
      name: "WETH",
      initialweight: "15:02%",
      val: "7.0674",
      amount: "$30,000",
    },
  ];
  return (
    <div className="weighted_pools innerheader">
      <Header />
      <div className="container py-4">
        <div className="row top">
          <div className="col-xl-8 col-lg-8">
            <p className="header mb-2">Weighted Pool</p>
            <p className="sub_head mb-0 d-flex align-items-center">
              Fixed swap fees:0.01%
              <img
                src={detail}
                className="detail_icon img-fluid ps-2"
                alt="detail_icon"
              />
            </p>
            <div className="coin_col_sec my-4">
              <div className="coins_list">
                <div className="">
                  <div className="coin">
                    <div className="name d-flex align-items-center">
                      <img src={Bal} alt="bal" className="ing-fluid pe-1" />
                      <span>BAL</span>
                    </div>
                    <div className="percentage">50%</div>
                  </div>
                </div>
                <div className="">
                  <div className="coin">
                    <div className="name d-flex align-items-center">
                      <img src={Weth} alt="weth" className="ing-fluid pe-1" />
                      <span>WETH</span>
                    </div>
                    <div className="percentage">15%</div>
                  </div>
                </div>
                <div className="">
                  <div className="coin">
                    <div className="name d-flex align-items-center">
                      <img src={Mkr} alt="bal" className="ing-fluid pe-1" />
                      <span>MKR</span>
                    </div>
                    <div className="percentage">15%</div>
                  </div>
                </div>
                <div className="">
                  <div className="coin">
                    <div className="name d-flex align-items-center">
                      <img src={Wbtc} alt="bal" className="ing-fluid pe-1" />
                      <span>WBTC</span>
                    </div>
                    <div className="percentage">15%</div>
                  </div>
                </div>
                <div className="">
                  <div className="coin">
                    <div className="name d-flex align-items-center">
                      <img src={Usdc} alt="bal" className="ing-fluid pe-1" />
                      <span>USDC</span>
                    </div>
                    <div className="percentage">5%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card_sec">
              <Card className="">
                <Card.Body className="p-0">
                  <p className="not_enough_txt mb-0 d-flex align-items-center">
                    <img
                      src={Notenough}
                      alt="no-data"
                      className="pe-2 img-fluid"
                    />
                    <span>Not enough data</span>
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="values_data my-4">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-3 mb-sm-3 mb-md-0 mb-lg-0 mb-xl-0">
                  <div className="content">
                    <p className="name mb-1">Pool Value</p>
                    <p className="val mb-0">$199,696</p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-3 mb-sm-3 mb-md-0 mb-lg-0 mb-xl-0">
                  <div className="content">
                    <p className="name mb-1">Volume (24h)</p>
                    <p className="val mb-0">$0.00</p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-3 mb-sm-3 mb-md-0 mb-lg-0 mb-xl-0">
                  <div className="content">
                    <p className="name mb-1">Fees (24h)</p>
                    <p className="val mb-0">$0.00</p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-3 mb-sm-3 mb-md-0 mb-lg-0 mb-xl-0">
                  <div className="content">
                    <p className="name mb-1">APR</p>
                    <p className="val mb-0">$0.00</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="common_table_style my-4">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="token_th">Token</th>
                    <th className="weight weight_th">
                      Weight <BsArrowDown className="ps-1" />
                    </th>
                    <th className="balance_th">Balance</th>
                    <th className="value_th">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Pools.map((item) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src={item.token[0].icon}
                                alt={item.token[0].name}
                                className="img-fluid icon me-2"
                              />
                              <span className="name">
                                {item.token[0].name} <FiArrowUpRight />
                              </span>
                            </div>
                          </td>
                          <td>{item.weight}</td>
                          <td>{item.balance}</td>
                          <td>{item.value}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="pool-transaction">
              <p className="pool_txt">Pool transaction</p>
              <div className="tabs_sec my-4">
                <Tabs
                  defaultActiveKey="home"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="home" title="Investments">
                    <div className="investment_content">
                      <div className="common_table_style my-4">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th className="detail_th">Details</th>
                              <th>Value</th>
                              <th className="time_th">
                                Time <BsArrowDown className="ps-1" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {PoolTransaction.map((item) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <BiPlus className="me-1" />{" "}
                                        <span>Invest</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="detail_cont_td">
                                        {item?.details?.map((itemm) => {
                                          return (
                                            <>
                                              <div className="detail_content_data me-2 mb-2">
                                                <img
                                                  src={Weth}
                                                  className="img-fluid me-1"
                                                />
                                                <span className="value">
                                                  {itemm.val}
                                                </span>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                    </td>
                                    <td>{item.value}</td>
                                    <td>{item.time}</td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="profile" title="Swaps">
                    No Data
                  </Tab>
                  <Tab
                    eventKey="contact"
                    title="My investments"
                    className="my_investment_item"
                  >
                    No Data
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-6">
                <div className="my_pool_balance">
                  <Card className="">
                    <Card.Body className="p-0">
                      <div className="d-flex justify-content-between header_sec">
                        <p className="mb-0">My pool balance</p>
                        <p className="mb-0">$199.696</p>
                      </div>
                      <hr className="m-0" />

                      <div className="content_data">
                        {MypoolBalance.map((item) => {
                          return (
                            <>
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={item.icon}
                                    alt={item.name}
                                    className="img-fluid me-2"
                                  />
                                  <div>
                                    <p className="mb-0">
                                      {item.percentage}{" "}
                                      <span className="txt_bold">
                                        {item.name}
                                      </span>
                                    </p>
                                    <p className="mb-0 btm_txt">
                                      Initial weight: {item.initialweight}
                                    </p>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <p className="mb-0">{item.val}</p>
                                  <p className="mb-0 btm_txt">{item.amount}</p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-6">
                <div className="pools_tokens_wallet mt-4 mt-md-0 mt-lg-4 mt-xl-4">
                  <Card className="">
                    <Card.Body className="p-0">
                      <div className="content_section">
                        <p className="mb-0 head">
                          Based on pool tokens in your wallet
                        </p>
                        <div className="btn_content row mt-3 mb-2">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <p className="mb-2 you_can_invest">
                              You can invest
                            </p>
                            <Button className="invest_btn searchbtn">
                              Invest
                            </Button>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <p className="mb-2 amount">$534,768</p>
                            <Button className="withdraw_btn searchbtn">
                              Withdraw
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default WeightedPool;
