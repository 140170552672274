import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Button } from "react-bootstrap";
import { FiArrowUpRight } from "react-icons/fi";
import Card from "react-bootstrap/Card";
import UnlockClock from "../../Assets/unlock_clock.png";
import lockClock from "../../Assets/lock_clock.png";
import InformationLogo from "../../Assets/information.png";
import { FiFilter } from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import WorldLogo from "../../Assets/world-logo.png";
import Table from "react-bootstrap/Table";
import CoinLight from "../../Assets/ph_coins-light.png";
import EthIcon from "../../Assets/eth.png";
import DaIcon from "../../Assets/multi-collateral-dai-dai-logo.png";
import EthSvgrepo from "../../Assets/eth-svgrepo.png";
import DownArrow from "../../Assets/down_arrow.png";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Form from "react-bootstrap/Form";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

function Vephor() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  const PoolsTable = [
    {
      EthSvgrepo: EthSvgrepo,
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "PHOR 80%",
        },
        {
          val: "WETH 80%",
        },
      ],
      poolvalue: "$127,167,635",
      type: "percentage",
    },
    {
      EthSvgrepo: EthSvgrepo,
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "rETH",
        },
        {
          val: "WETH",
        },
      ],
      poolvalue: "$45,189,636",
      type: "text",
    },
    {
      EthSvgrepo: EthSvgrepo,
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "WETH",
        },
      ],
      poolvalue: "$22,522,768",
      type: "text",
    },
    {
      EthSvgrepo: EthSvgrepo,
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "sfrxETH ",
        },
        {
          val: "sfrxETH ",
        },
      ],
      poolvalue: "$22,522,768",
      type: "text",
    },
    {
      EthSvgrepo: EthSvgrepo,
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "PHOR 80%",
        },
        {
          val: "WETH 80%",
        },
      ],
      poolvalue: "$127,167,635",
      type: "percentage",
    },
    {
      EthSvgrepo: EthSvgrepo,
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "rETH",
        },
        {
          val: "WETH",
        },
      ],
      poolvalue: "$45,189,636",
      type: "text",
    },
    {
      EthSvgrepo: EthSvgrepo,
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "WETH",
        },
      ],
      poolvalue: "$22,522,768",
      type: "text",
    },
    {
      EthSvgrepo: EthSvgrepo,
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "sfrxETH ",
        },
        {
          val: "sfrxETH ",
        },
      ],
      poolvalue: "$22,522,768",
      type: "text",
    },
  ];
  const FilterDropNetwork = [
    {
      name: "Ethereum",
    },
    {
      name: "Optimism",
    },
    {
      name: "Gnosis Chain",
    },
    {
      name: "Polygon Pos",
    },
    {
      name: "Polygon zkEVM",
    },
    {
      name: "Base",
    },
    {
      name: "Arbitrum",
    },
    {
      name: "Avalanche",
    },
  ];
  return (
    <div className="vephor_page innerheader">
      <Header />
      
      <div className="top">
      <div className="head_section py-5">
        <div className="text-center">
          <h3 className="head">Extra earnings & voting power</h3>
          <p>-Boost liquidity mining yield up to 2.5x</p>
          <p>-Vote to directly mining emissions</p>
          <p>-Earn your share of protocol revenue</p>
          <div className="btn_sec">
            <Button className="white_bg_btn get_vephor_btn">Get vePHOR</Button>
            <Button className="get_vephor_btn trans_bg_btn">
              Get vePHOR <FiArrowUpRight />
            </Button>
          </div>
        </div>
      </div>

      <div className="content_sec py-5">
        <div className="container">
          <h3 className="heading py-4 text-center">Manage your vePHOR</h3>
          <div className="row">
            <div className="card_sec col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 unlock_txt">Unlock ve8020 PHOR/WETH</p>
                    <div className="clock_bg">
                      <img
                        src={UnlockClock}
                        className="img-fluid unlock_lock"
                        alt="unlock"
                      />
                    </div>
                  </div>
                  <div>
                    <p className="count">0</p>
                    <p className="amount">$0.00</p>
                  </div>
                  <div className="btn_sec mb-2">
                    <Link to="/add-liquidity" className="text-decoration-none">
                      <Button className="card_btn liquidity">
                        Add Liquidity
                      </Button>
                    </Link>
                    <Button className="card_btn lock">Lock for vePHOR</Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="card_sec col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 unlock_txt">Locked ve8020 PHOR/WETH</p>
                    <div className="clock_bg">
                      <img
                        src={lockClock}
                        className="img-fluid unlock_lock"
                        alt="lock"
                      />
                    </div>
                  </div>
                  <div>
                    <p className="count">0</p>
                    <p className="amount">$0.00</p>
                  </div>
                  <div className="btn_sec mb-2">
                    <Button className="card_btn lock">Extend Lock</Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="text-center vephor_boosts">
            <h3 className="heading text-center">Cross chain vePHOR boosts</h3>
            <h6 className="vephor_boosts_sub_text mt-4">
              Once you have some veBAL, Sync your balance here to other networks{" "}
            </h6>
          </div>
          <div className="pools_eligible pt-2 pt-sm-3 pt-md-3 pt-lg-5 pt-xl-5">
            <h3 className="heading pb-4 text-center">
              Pools eligible for liquidity mining{" "}
              <img
                src={InformationLogo}
                alt="information_logo"
                className="img-fluid info_icon ps-2"
              />
            </h3>
            <div className="table_filter_sec row">
              <div className="sec_fst col-xl-6 col-lg-6 mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0">
                <div className="unallocated_votes">
                  <p className="mb-0 mb-sm-2 mb-md-2 mb-lg-2 mb-xl-2">
                    My unallocated votes
                    <img
                      src={InformationLogo}
                      alt="information_logo"
                      className="img-fluid info_icon ps-2"
                    />
                  </p>
                  <p className="mb-0">---</p>
                </div>
                <div className="unallocated_votes">
                  <p className="mb-0 mb-sm-2 mb-md-2 mb-lg-2 mb-xl-2">
                    My unallocated votes
                    <img
                      src={InformationLogo}
                      alt="information_logo"
                      className="img-fluid info_icon ps-2"
                    />
                  </p>
                  <p className="mb-0 date">0d : 12h: 2m: 14s</p>
                </div>
              </div>
              <div className="sec_snd col-xl-6 col-lg-6 ">
                <input
                  type="text"
                  id="myInput"
                  placeholder="Filter by token"
                  title="Filter by token"
                />
                <Dropdown className="filter_dropdown">
                  <Dropdown.Toggle>
                    <FiFilter /> More filters
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div>
                      <div className="network pool_gauge">
                        <h6 className="mb-1">Network</h6>
                      </div>

                      <div className="custom_content_sec">
                        {FilterDropNetwork.map((item) => {
                          return (
                            <>
                              <div className="content mb-2">
                                <Form.Check type="checkbox" />{" "}
                                <span className="ps-2">{item.name}</span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div>
                      <div className="network pool_gauge">
                        <h6 className="mb-1">Pool gauge display</h6>
                      </div>

                      <div className="custom_content_sec">
                        <div className="content mb-2">
                          <Form.Check type="checkbox" />{" "}
                          <span className="ps-2">Show expired pools</span>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="common_table_style mt-4">
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <div className="ms-2">
                        <img
                          src={WorldLogo}
                          alt="WorldLogo"
                          className="img-fluid world_icon"
                        />
                      </div>
                    </th>
                    <th className="coinlight_th">
                      <div className="coinlight_img">
                        <img
                          src={CoinLight}
                          alt="information_logo"
                          className="img-fluid info_icon"
                        />
                      </div>
                    </th>
                    <th>Composition</th>
                    <th className="pool_value_th">
                      Pool value <BsArrowDown />
                      {/* <BsArrowUp /> */}
                    </th>
                  </tr>
                </thead>
                <tbody className="t_body">
                  {PoolsTable.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="EthSvgrepo_sec">
                              <img
                                src={item.EthSvgrepo}
                                alt="EthSvgrepo"
                                className="img-fluid EthSvgrepo"
                              />
                            </div>
                          </td>
                          <td className="coinlight_td">
                            <div className="pools_icons_collapse">
                              <div className="position-relative">
                                {item.DaLogo.map((itemlogo) => {
                                  return (
                                    <>
                                      <img
                                        src={itemlogo.Icon}
                                        alt="EthIcon"
                                        className="img-fluid"
                                      />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="composition_sec">
                              {item.composition.map((itemm) => {
                                return (
                                  <>
                                    <span
                                      className={
                                        item.type == "percentage"
                                          ? "high_radius"
                                          : "low_radius"
                                      }
                                    >
                                      {itemm.val}
                                    </span>
                                  </>
                                );
                              })}
                            </div>
                          </td>
                          <td>{item.poolvalue}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      </div>

      <Footer />
    </div>
  );
}

export default Vephor;
