import React from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import Lottie from "lottie-react";
import birdimage from "../Jsonfile/bird_2 (1).json";
import birdimage1 from "../Jsonfile/bird21.json";
import seawave from "../Jsonfile/sea base.json";
import vehicles from "../Jsonfile/vehicles2.json"
import sunimg from "../Assets/sun.png"
import island from "../Assets/island.png"
import cloudshade from "../Assets/backs.png";

export default function Section1() {

  return (
    <>
      <div>

        <div className="bottom_sec">
          <Lottie className="seawave" animationData={seawave} />
          <img src={cloudshade} className="cloudshade" alt="sun" />
          <img src={island} className="island" alt="sun" />
          <Lottie className="vehicles" animationData={vehicles} loop={true} />
        </div>

        <div className="section1">
          <div className="container">
            <Lottie className="birdimage" animationData={birdimage} loop={true} />
            <Lottie className="birdimage1" animationData={birdimage1} loop={true} />
            <Lottie className="birdimage2" animationData={birdimage1} loop={true} />
            <img src={sunimg} className="sunimg" alt="sun" />
            <div className="height topminus">
              <h1><span className="heading1">Buy and Sell Instantly</span><br></br>
                Whoever, Whenever, Wherever.</h1>
              <div className="row">
                <div className="col-6 col-md-3 col-sm-6 mt-2">
                  <h3>$0.59</h3>
                  <p className="mb-0 smalltext">Price</p>
                </div>
                <div className="col-6 col-md-3 col-sm-6 mt-2">
                  <h3>$367.16m</h3>
                  <p className="mb-0 smalltext">Total Liquidity</p>
                </div>
                <div className="col-6 col-md-3 col-sm-6 mt-2">
                  <h3>$268.37b</h3>
                  <p className="mb-0 smalltext">Total Volume</p>
                </div>
                <div className="col-6 col-md-3 col-sm-6 mt-2">
                  <h3>49.90k</h3>
                  <p className="mb-0 smalltext">Total Paris</p>
                </div>
                <p className="para mt-3">Unlock the world of cryptocurrency trading. Experience the freedom
                  {/* <br></br> */}
                  to trade over 400 tokens instantly, no registration needed.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}      