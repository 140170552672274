import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Fullpage from "./Pages/FullPage";
import Swappage from "./Pages/Subpages/swappage";
import Createpool from "./Pages/Subpages/createpool";

import Loader from "./Components/loader";
import Claim from "./Pages/Subpages/Claim";
import './App.css';
import './Pages/Responsive.css';
import Portfolio from "./Pages/Subpages/Portfolio";
import Portfolioedit from "./Components/remove-usdt";
import Vephor from "./Pages/Subpages/Vephor";
import AddLiquidity from "./Pages/Subpages/AddLiquidity";
import Pools from "./Pages/Subpages/Pools";
import Loaders from "./Components/loader";

import WeightedPool from "./Pages/Subpages/WeightedPool";


export default function App() {


  


  return (
    <>
     
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Fullpage />} />
          <Route path="/swap" element={<Swappage />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/pools" element={<Pools />} />
          <Route path="/weighted-pools" element={<WeightedPool />} />

          <Route path="/claim" element={<Claim />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolioedit" element={<Portfolioedit />} />
          <Route path="/vephor" element={<Vephor />} />
          <Route path="/add-liquidity" element={<AddLiquidity />} />
          <Route path="/createpool" element={<Createpool />} />

        </Routes>
      </BrowserRouter>
       
    </>
  );
}
