import React, { useEffect, useState } from "react";
import Header from "./Header";
import Section1 from "./Section1"
import Section2 from "./Section2"
import Section3 from "./Section3"
import Section4 from "./Section4"
import Section5 from "./Section5"
import Section6 from "./Section6"
import Footer from "./Footer";
import Loaders from "../Components/loader";

export default function FullPage() {

      const [loading, setLoading] = useState(true);
      const spinner = document.getElementById("spinner");
      var element = document.getElementById("bodystyle");   
      useEffect(()=>{
            setLoading(false)
            setTimeout(() => {
              spinner.style.display = "none";       
              element.classList.remove("bodyname");                   
            }, 1300);
      })
      // if (spinner) {
      //   document.addEventListener('readystatechange', event => {    
      //     if (event.target.readyState === "complete") {       
      //       setLoading(false)
      //       setTimeout(() => {
      //         spinner.style.display = "none";       
      //         element.classList.remove("bodyname");                   
      //       }, 2000);
      //     }
      // });
          
      // }

      useEffect(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }, []);
          
      return (
            <>
             { !loading &&
      
                  <div className="fullpage">
                        <div className="headers"><Header /></div>
                        <section className="one">
                              <Section1 />
                        </section>
                        <section className="two">
                              <Section2 />
                        </section>
                        <section className="three">
                              <Section3 />
                        </section>
                        <section className="four">
                              <Section4 />
                        </section>
                        <section className="five">
                              <Section5 />
                        </section>
                        <section className="six">
                              <Section6 />
                        <div className="homefooter"><Footer /></div>
                        </section>
                  </div>
  } 

            </>
      );
}