import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Button, Container, Row, Dropdown, ProgressBar, Spinner } from 'react-bootstrap';
import Eth from "../../Assets/eth.png";
import Dai from "../../Assets/dai.png";
import Usdc from "../../Assets/usdc.svg";
import Bal from "../../Assets/Bal.svg";
import Snx from "../../Assets/snx.svg";
import { BiSearch, BiSolidLockOpen, BiSolidEdit } from 'react-icons/bi';
import { RiDeleteBinFill } from 'react-icons/ri';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Donutcharts from "../../Components/piechart";
import { Link } from "react-router-dom";

export default function Pooldetail() {

    const [stepsss, setStepsss] = useState([

    ]);

    const [steps, setSteps] = useState(1);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [steps]);



    let regex = /^[0-9]+$/;
    const [value, setValue] = useState(50);



    const [checkedopen, setCheckedopen] = useState(true);

    const [filterRows, setFilterRows] = useState([{ filters: "" }]);

    const addFilters = () => {
        setFilterRows([...filterRows, { filters: "" }])
    };

    const removeFilters = (index) => {
        const list = [...filterRows]
        list.splice(index, 1)
        setFilterRows(list)
    };

    const [coinlist, setCoinlist] = useState([
        {
            "id": 1, "image": Eth, "coinname": "WETH", "cointext": "wrapped Ether"
        },
        {
            "id": 2, "image": Dai, "coinname": "DAI", "cointext": "Dai Stablecoin"
        },
        {
            "id": 3, "image": Usdc, "coinname": "USDC", "cointext": "USD Coin"
        },
        {
            "id": 4, "image": Bal, "coinname": "BAL", "cointext": "Balancer"
        },
        {
            "id": 5, "image": Snx, "coinname": "SNX", "cointext": "Synthetix Network Token"
        },
    ]);

    const [interchange, setInterchange] = useState(
        {
            "id": 1, "image": Eth, "coinname": "WETH", "cointext": "wrapped Ether"
        }
    );

    return (

        <>

            <div className="portfoliodetail innerheader">
                <div className="headers"> <Header /></div>

                <div className="container top">
                    <div className="row">

                        <div className="col-md-5 col-lg-3 mt-3">
                            <div className="weightpoolborder listed">
                                <h6 className="fw-600 pt-3 px-3 pb-3 mb-0">Create a weighted pool steps</h6>
                                <ul className="mt-2">
                                    <li className={steps == 1 ? "active" : ""}><span>1</span> <span>Choose Tokens & Weights</span></li>
                                    <li className={steps == 2 ? "active" : ""}><span>2</span> <span>Set Pool Fees</span></li>
                                    <li className={steps == 3 ? "active" : ""}><span>3</span> <span>Set Initial Liquidity</span></li>
                                    <li className={steps == 4 ? "active" : ""}><span>4</span> <span>Confirm pool creation</span></li>
                                </ul>


                            </div>
                        </div>


                        <div className="col-md-7 col-lg-5 mt-3">

                            <div className="swaplightborder">
                                <p className="mb-2">Ethereum Mainet</p>

                                {steps == 1 &&
                                    <div>
                                        <h6 className="title mb-2">Choose tokens & weights</h6>
                                        <div className="innerborder">
                                            <span className="backclr d-flex pt-2 pb-2 px-3 justify-content-between"><h6 className="title mb-0">Token</h6> <h6 className="title mb-0">weight</h6></span>

                                            <div className="whtbackclr">
                                                {filterRows.map((index, id) => (
                                                    <div className="d-flex justify-content-between mb-3">
                                                        <div className="commonborder">
                                                            <Dropdown>
                                                                <Dropdown.Toggle className="drop" variant="success" id="dropdown-basic">
                                                                    <img src={interchange.image} className="ethimg me-1" alt="eth" />
                                                                    {interchange.coinname}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <h6 className="fw-600 mb-3">Token Search</h6>
                                                                    <div class="input-group mb-3">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text" id="basic-addon1"><BiSearch className="search" /></span>
                                                                        </div>
                                                                        <input type="search" class="form-control" placeholder="Search by name, symbol or address" aria-label="Username" aria-describedby="basic-addon1" />
                                                                    </div>
                                                                    <div className="menuheight">
                                                                        {coinlist.map((e, i) =>
                                                                            <Dropdown.Item className={e.id == interchange.id ? "hideclass" : ""} onClick={() => { setInterchange(e) }}>
                                                                                <div className="d-flex align-items-center">
                                                                                    <img src={e.image} className="ethimg me-3" alt="eth" />
                                                                                    <div>
                                                                                        <p className="mb-0 coinname">{e.coinname}</p>
                                                                                        <p className="mb-0 coinsubtext">{e.cointext}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                        )}
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="ko">
                                                                <input type="text" className="form-control" value={value} onChange={(e) => { if (e.target.value == '' || regex.test(e.target.value)) { setValue(e.target.value) } }} />
                                                            </div>
                                                            <BiSolidLockOpen className="icon ms-2 me-3" />
                                                            <RiDeleteBinFill style={{ cursor: "pointer" }} onClick={removeFilters} className="icon" />
                                                        </div>
                                                    </div>
                                                ))}
                                                <Button className="addtokenbtn" onClick={addFilters} variant="light">Add token</Button>
                                            </div>

                                            <div className="backclr bottomclr pt-2 pb-3 px-3">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <h6 className="title mb-0">Total allocated</h6> <h6 className="title mb-0">0.00%</h6>
                                                </div>
                                                <span className="pt-3"><ProgressBar now={10} /></span>
                                            </div>
                                        </div>
                                        <div className="instructionborder mt-2 py-2 px-3">
                                            <span className="textclr"><AiOutlineInfoCircle /> <span>It’s  recommended to provide new pools with
                                                at least $20,000 in initial funds</span> </span>
                                            <p className="textclr mt-2">Based  on your wallet balances for these tokens, the
                                                maximum amount you can fund this pool with is ~$0.00.</p>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6 mx-auto"> <Button className="nextbtn w-100" onClick={() => setSteps(2)} variant="light">Next</Button> </div>
                                        </div>
                                    </div>
                                }

                                {steps == 2 &&
                                    <div>
                                        <h6 className="title mb-2" style={{ cursor: "pointer" }} onClick={() => setSteps(1)}><MdOutlineKeyboardArrowLeft className="leftarrow" /> Set pool fees</h6>
                                        <h5 className="title mb-2">Initial swap fees</h5>

                                        <p className="dullclr">0.30% is best for most weighted pools with established
                                            token. Go higher for more exotic tokens.</p>
                                        <div class="d-flex mb-3">
                                            <button class="btn btn-outline-secondary btn-sm me-3" type="button">0.5%</button>
                                            <button class="btn btn-outline-secondary btn-sm me-3" type="button">1.0%</button>
                                            <button class="btn btn-outline-secondary btn-sm me-3" type="button">2.0%</button>
                                            <button class="btn btn-outline-secondary btn-sm" type="button">2.5%</button>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={checkedopen} onClick={() => setCheckedopen(!checkedopen)} />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Allow balancer governance to manage fees
                                            </label>
                                        </div>

                                        {checkedopen ?
                                            <>
                                            </>
                                            :
                                            <div>
                                                <h5 className="title mt-3 mb-2">Alternative fee management options</h5>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label class="form-check-label" for="flexRadioDefault1">
                                                        Allow balancer governance to manage fees
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" />
                                                    <label class="form-check-label" for="flexRadioDefault2">
                                                        Allow dynamic fees from an address i choose
                                                    </label>
                                                </div>
                                            </div>}

                                        <div className="row mt-3">
                                            <div className="col-md-6 mx-auto"> <Button className="nextbtn w-100" onClick={() => setSteps(3)} variant="light">Next</Button> </div>
                                        </div>
                                    </div>
                                }

                                {steps == 3 &&
                                    <div>
                                        <h6 className="title mb-2" style={{ cursor: "pointer" }} onClick={() => setSteps(1)}><MdOutlineKeyboardArrowLeft className="leftarrow" /> Set initial liquidity</h6>
                                        <h5 className="title mb-2">Initial swap fees</h5>
                                        {coinlist.map((e, i) =>
                                            <div className="whiteborder commonborder mb-2 py-3 px-2">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="drop" variant="success" id="dropdown-basic">
                                                            <img src={interchange.image} className="ethimg me-1" alt="eth" />
                                                            {interchange.coinname} 50%
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <h6 className="fw-600 mb-3">Token Search</h6>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text" id="basic-addon1"><BiSearch className="search" /></span>
                                                                </div>
                                                                <input type="search" class="form-control" placeholder="Search by name, symbol or address" aria-label="Username" aria-describedby="basic-addon1" />
                                                            </div>
                                                            <div className="menuheight">
                                                                {coinlist.map((e, i) =>
                                                                    <Dropdown.Item className={e.id == interchange.id ? "hideclass" : ""} onClick={() => { setInterchange(e) }}>
                                                                        <div className="d-flex align-items-center">
                                                                            <img src={e.image} className="ethimg me-3" alt="eth" />
                                                                            <div>
                                                                                <p className="mb-0 coinname">{e.coinname}</p>
                                                                                <p className="mb-0 coinsubtext">{e.cointext}</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                )}
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="textclr mb-0">4803,07396733</p>
                                                </div>


                                                <div className="mt-2 mb-2 d-flex justify-content-between align-items-cente">
                                                    <span className="textclr mb-0"><span>Balance: 15,000</span> <span className="max">MAX</span> </span>
                                                    <p className="textclr mb-0">$1,00,000</p>
                                                </div>
                                                <span className="pt-2 pb-2"><ProgressBar now={20} /></span>
                                            </div>
                                        )}

                                        <div className="row mt-3">
                                            <div className="col-md-6 mx-auto"> <Button className="nextbtn w-100" onClick={() => setSteps(4)} variant="light">Preview</Button> </div>
                                        </div>
                                    </div>
                                }

                                {steps == 4 &&
                                    <div>

                                        <h6 className="title mb-2" style={{ cursor: "pointer" }} onClick={() => setSteps(3)}><MdOutlineKeyboardArrowLeft className="leftarrow" /> Preview new weighted pool</h6>

                                        {coinlist.map((e, i) =>
                                            <div className="d-flex justify-content-between previewborder">
                                                <div className="d-flex">
                                                    <img src={Eth} className="img-fluid coinimg me-2" />
                                                    <div>
                                                        <p className="textclr mb-0">50.00% <span className="fw-600">WETH</span></p>
                                                        <p className="lightgray mb-0">Initial weight: 15.02%</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="textclr mb-0">0.529 </p>
                                                    <p className="lightgray mb-0">$30,000</p>
                                                </div>
                                            </div>

                                        )}

                                        <div className="d-flex justify-content-between mt-3">
                                            <h5 className="themeclr fw-600">Total</h5> <h5 className="themeclr fw-600">$ 199,785</h5>
                                        </div>

                                        <div className="innerborder mt-2">
                                            <span className="backclr d-flex pt-2 pb-2 px-3 justify-content-between"><h6 className="title mb-0">Summary</h6> </span>
                                            <div className="whtbackclr bord_bot_rad">
                                                <div className="d-flex justify-content-between">
                                                    <p className="textclr mb-2">Pool symbol:</p><p className="textclr mb-2">50BAL-15WETH-15MKR- <BiSolidEdit /></p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p className="textclr mb-2">Pool name:</p><p className="textclr mb-2">50BAL-15WETH-15MKR- <BiSolidEdit /></p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p className="textclr mb-2">Pool weight:</p><p className="textclr mb-2">Weighted <BiSolidEdit /></p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p className="textclr mb-2">Swap fee:</p><p className="textclr mb-2">1.00% <BiSolidEdit /></p>
                                                </div>
                                            </div>

                                            <div className="confirmlisted">
                                                <ul>
                                                    <li>
                                                        <span>2</span>
                                                        {/* <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg> */}
                                                    </li>
                                                    <li>
                                                        <span>3</span>
                                                        {/* <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg> */}
                                                    </li>
                                                    <li>
                                                        <span>4</span>
                                                        {/* <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg> */}
                                                    </li>
                                                    <li>
                                                        <span>5</span>
                                                        {/* <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg> */}
                                                    </li>
                                                    <li>
                                                        <span>6</span>
                                                        {/* <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg> */}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>



                                        <div className="row mt-3">
                                            <div className="col-md-7 mx-auto">
                                                <Button className="nextbtn w-100"  onClick={() => setSteps(5)} variant="light">Approve BAL for investing</Button>
                                                {/* <Button className="nextbtn w-100" variant="primary" disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        Confirming...
                                                    </Button> */}
                                            </div>
                                        </div>
                                    </div>
                                }

                                {steps == 5 &&
                                    <div>

                                        <h6 className="title mb-2" style={{ cursor: "pointer" }} onClick={() => setSteps(3)}><MdOutlineKeyboardArrowLeft className="leftarrow" /> Preview new weighted pool</h6>

                                        {coinlist.map((e, i) =>
                                            <div className="d-flex justify-content-between previewborder">
                                                <div className="d-flex">
                                                    <img src={Eth} className="img-fluid coinimg me-2" />
                                                    <div>
                                                        <p className="textclr mb-0">50.00% <span className="fw-600">WETH</span></p>
                                                        <p className="lightgray mb-0">Initial weight: 15.02%</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="textclr mb-0">0.529 </p>
                                                    <p className="lightgray mb-0">$30,000</p>
                                                </div>
                                            </div>

                                        )}

                                        <div className="d-flex justify-content-between mt-3">
                                            <h5 className="themeclr fw-600">Total</h5> <h5 className="themeclr fw-600">$ 199,785</h5>
                                        </div>

                                        <div className="innerborder mt-2">
                                            <span className="backclr d-flex pt-2 pb-2 px-3 justify-content-between"><h6 className="title mb-0">Summary</h6> </span>
                                            <div className="whtbackclr bord_bot_rad">
                                                <div className="d-flex justify-content-between">
                                                    <p className="textclr mb-2">Pool symbol:</p><p className="textclr mb-2">50BAL-15WETH-15MKR- <BiSolidEdit /></p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p className="textclr mb-2">Pool name:</p><p className="textclr mb-2">50BAL-15WETH-15MKR- <BiSolidEdit /></p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p className="textclr mb-2">Pool weight:</p><p className="textclr mb-2">Weighted <BiSolidEdit /></p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p className="textclr mb-2">Swap fee:</p><p className="textclr mb-2">1.00% <BiSolidEdit /></p>
                                                </div>
                                            </div>

                                            <div className="confirmlisted">
                                                <ul>
                                                    <li>
                                                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    </li>
                                                    <li>
                                                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    </li>
                                                    <li>
                                                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    </li>
                                                    <li>
                                                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    </li>
                                                    <li>
                                                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>



                                        <div className="row mt-3">
                                            <div className="col-md-7 mx-auto">
                                                <Link to="/weighted-pools"><Button className="nextbtn w-100" variant="light">View Pool</Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                }



                            </div>

                        </div>

                        <div className="col-md-7 col-lg-4 mt-3">
                            <div className="weightpoolborder">
                                <h6 className="fw-600 pt-3 px-3 pb-3 mb-0">Pool summary</h6>
                                {console.log('jdhfdsjkhfds',stepsss)}
                                <div className="pt-2 pb-2"><Donutcharts setStepsss={setStepsss}/></div>
                                <p className="mt-2 mb-2 fw-600 textclr text-center">In your wallet</p>
                                <h5 className="mt-0 mb-3 text-center">$0.00</h5>
                            </div>

                            {steps == 1 &&
                                <div className="weightpoolborder mt-3">
                                    <h6 className="fw-600 pt-3 px-3 pb-3 mb-0">Max initial liquidity in wallet</h6>
                                    <div className="row tokenbottom px-3">
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 mz textclr">Token</h5><p className="tabname  mb-2">Unallocated</p></div>
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 mz textclr">USD Value</h5><p className="tabname mb-2">-</p></div>
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 mz textclr">Pool %</h5><p className="tabname mb-2">100%</p></div>
                                        <hr></hr>
                                    </div>
                                    <div className="row px-3">
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">Total</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">$0.00</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">0%</p></div>
                                    </div>
                                </div>}
                            {steps == 2 &&
                                <div className="weightpoolborder mt-3">
                                    <h6 className="fw-600 pt-3 px-3 pb-3 mb-0">Token Prices</h6>
                                    <div className="d-flex pt-2 px-3 justify-content-between">
                                        <p className="tabname mb-2">SNX</p>
                                        <p className="tabname mb-2">$2.62</p>
                                    </div>
                                    <div className="d-flex pt-2 px-3 justify-content-between">
                                        <p className="tabname mb-2">AAVE</p>
                                        <p className="tabname mb-2">$97.80</p>
                                    </div>
                                </div>}
                            {steps == 3 &&
                                <div className="weightpoolborder mt-3">
                                    <h6 className="fw-600 pt-3 px-3 pb-3 mb-0">Max initial liquidity in wallet</h6>
                                    <div className="row tokenbottom px-3">
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 textclr">Token</h5><p className="tabname mb-2">Unallocated</p></div>
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 textclr">USD Value</h5><p className="tabname mb-2">-</p></div>
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 textclr">Pool %</h5><p className="tabname mb-2">100%</p></div>
                                        <hr></hr>
                                    </div>
                                    <div className="row px-3">
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">Total</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">$0.00</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">0%</p></div>
                                    </div>
                                </div>}
                            {steps == 4 &&
                                <div className="weightpoolborder mt-3">
                                    <h6 className="fw-600 pt-3 px-3 pb-3 mb-0">Max initial liquidity in wallet</h6>
                                    <div className="row  px-3">
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 textclr">Token</h5></div>
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 textclr">USD Value</h5></div>
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 textclr">Pool %</h5></div>

                                    </div>
                                    {
                                    stepsss?.label?.length > 0 &&
                                    stepsss.label.map((e, i) =>
                                    <div className="row px-3">                  
                                        <div className="col-md-4 col-4"> <p className="tabname d-flex align-items-center mb-2"><span className="colorwidth me-2" style={{ backgroundColor:stepsss?.colors?.[i] }}></span>{e}</p></div>
                                    
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">$100,000</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">100%</p></div>
                                    
                                    </div>
                                        )}
                                            <hr></hr>
                                    <div className="row px-3">
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">Total</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">$0.00</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">0%</p></div>
                                    </div>
                                </div>}
                            {steps == 5 &&
                                <div className="weightpoolborder mt-3">
                                    <h6 className="fw-600 pt-3 px-3 pb-3 mb-0">Max initial liquidity in wallet</h6>
                                    <div className="row  px-3">
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 textclr">Token</h5></div>
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 textclr">USD Value</h5></div>
                                        <div className="col-md-4 col-4"> <h5 className="mt-2 mb-2 fw-700 textclr">Pool %</h5></div>

                                    </div>
                                    {
                                    stepsss?.label?.length > 0 &&
                                    stepsss.label.map((e, i) =>
                                    <div className="row px-3">                  
                                        <div className="col-md-4 col-4"> <p className="tabname d-flex align-items-center mb-2"><span className="colorwidth me-2" style={{ backgroundColor:stepsss?.colors?.[i] }}></span>{e}</p></div>
                                    
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">$100,000</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">100%</p></div>
                                    
                                    </div>
                                        )}
                                        <hr></hr>
                                    <div className="row px-3">
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">Total</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">$0.00</p></div>
                                        <div className="col-md-4 col-4"><p className="tabname mb-2">0%</p></div>
                                    </div>
                                </div>}

                        </div>



                    </div>

                </div>

                <div className="mt-5"><Footer /></div>

            </div>

        </>


    );
}