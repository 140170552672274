import React, { useRef, useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import Header from "../Pages/Header";
import Footer from "../Pages/Footer";
import { FaArrowLeft } from "react-icons/fa";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Eth from "../Assets/eth.png";

export default function Removedit() {

    const [sliding, setSliding] = useState(0);
    const handleOnChange = (value) => {
        setSliding(value)
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    return (

        <>
            <div className="portfolio_page remove innerheader">
                <Header />

                <div className="top mb-5">
                    <div className="container">
                        <div className="d-flex">
                            <Link to="/portfolio"><FaArrowLeft className="faarrow me-3" /></Link>
                            <div>
                                <h5 className="themeclr fw-500">
                                    Remove BNB-BUSD liquidity</h5>
                                <p>To receive BNB and BUSD</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <div className="swaplightborder">
                                    <span className="backclr d-flex pt-2 pb-3 px-3 justify-content-between"><h6 className="title mb-0">Amount</h6> <h6 className="title mb-0">Detailed</h6></span>

                                    <div className="innerborder py-3 px-3">
                                        <h5>{sliding}%</h5>
                                        <Slider
                                            min={0}
                                            max={100}
                                            value={sliding}
                                            onChange={handleOnChange}
                                            tooltip={false}
                                        />
                                        <div class="d-flex justify-content-between">
                                            <button class="btn btn-outline-secondary btn-sm px-lg-4" onClick={() => setSliding(25)} type="button">25%</button>
                                            <button class="btn btn-outline-secondary btn-sm px-lg-4" onClick={() => setSliding(50)} type="button">50%</button>
                                            <button class="btn btn-outline-secondary btn-sm px-lg-4" onClick={() => setSliding(75)} type="button">75%</button>
                                            <button class="btn btn-outline-secondary btn-sm px-lg-4" onClick={() => setSliding(100)} type="button">100%</button>
                                        </div>
                                    </div>

                                    <h6 className="themeclr mt-3 me-2">you will receive</h6>
                                    <div className="innerborder mt-2 py-2 px-3">
                                        <div className="d-flex justify-content-between previewborder">
                                            <div className="d-flex">
                                                <img src={Eth} className="img-fluid coinlistimg me-2" />
                                                <div>
                                                    <p className="textclr mb-0">WETH</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="textclr mb-0">0.529545 </p>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between previewborder">
                                            <div className="d-flex">
                                                <img src={Eth} className="img-fluid coinlistimg me-2" />
                                                <div>
                                                    <p className="textclr mb-0">WETH</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="textclr mb-0">0.529545 </p>
                                            </div>
                                        </div>
                                    </div>

                                    <h6 className="themeclr mt-3 me-2">Prices</h6>
                                    <div className="innerborder mt-2 py-2 px-3">
                                        <div className="d-flex justify-content-between previewborder">
                                            <p className="textclr mb-0">1 BNB*</p>
                                            <p className="textclr mb-0">1 BUSDT </p>
                                        </div>
                                        <div className="d-flex justify-content-between previewborder">
                                            <p className="textclr mb-0">1 BNB*</p>
                                            <p className="textclr mb-0">1 BUSDT </p>
                                        </div>
                                    </div>

                                    <div className="port_action_btn mt-3 mb-2 justify-content-center">
                                        <Button className="add_btn searchbtn">
                                            Enable
                                        </Button>
                                        <Button className="edit_btn searchbtn ms-3">
                                            Remove
                                        </Button>
                                    </div>


                                </div>
                            </div>

                            <div className="col-md-6 mt-3">
                                <div className="swaplightborder">
                                    <h6 className="themeclr mb-3 fw-700">LP tokens in your wallet</h6>
                                    <div className="d-flex justify-content-between">
                                        <p className="textclr mb-2"><img src={Eth} className="img-fluid coinlistimg me-2" />WETH</p><p className="textclr mb-2">0.023243</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="textclr mb-2">Share of Pool :</p><p className="textclr mb-2">0.63324</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="textclr mb-2">Pooled BNB :</p><p className="textclr mb-2">0.32533</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="textclr mb-2">Pooled BUSD :</p><p className="textclr mb-2">0.97234</p>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>

                <Footer />
            </div>

        </>
    );
}

