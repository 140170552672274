import React, { useRef, useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import logos from "../Assets/phorcys_logo.png";
import parrot from "../Assets/parrots.png";
import metamask from "../Assets/metamask.svg";
import walletbase from "../Assets/walletbase.svg";
import coinbase from "../Assets/coinbase.svg";

export default function Walletmodal(props) {

    const [Walletmodals, setWalletmodals] = useState(true)

    return (
        <>

            <div>
                <Modal
                    className="walletmodal"
                    onHide={props.onDismiss}
                    show={Walletmodals}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <img src={parrot} className="img-fluid parrotimg" alt="eth" />
                    <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
                    <Modal.Body>
                        <div className="walletbox pt-3 pb-3 px-2">
                            <h4 className="text-center modaltxtclr">Connect to a Wallet</h4>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                <label class="form-check-label" for="flexRadioDefault2">
                                    By connecting a wallet, I agree to Phorcys foundation's <a href="/"> Terms of Use</a>, <a href="/"> Risks</a>, <a href="/"> Cookies Policy</a>, use of <a href="/"> 3rd party services</a> and <a href="/"> Privacy Policy</a>.
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-4 mt-3 wall text-center">
                                    <img src={metamask} className="img-fluid metamask" alt="eth" />
                                    <p className="walletname mb-0">Metamask</p>
                                </div>
                                <div className="col-4 mt-3 wall text-center">
                                    <img src={walletbase} className="img-fluid metamask" alt="eth" />
                                    <p className="walletname mb-0">Wallet Connect</p>
                                </div>
                                <div className="col-4 mt-3 wall text-center">
                                    <img src={coinbase} className="img-fluid metamask" alt="eth" />
                                    <p className="walletname mb-0">Coinbase Wallet</p>
                                </div>
                            </div>
                            <div className="etherum mt-3 pt-2 pb-2 px-2">
                                <h5 className="modaltxtclr">New to ethereum?</h5>
                                <p className="mb-0">Balancer is a DeFi app on Ethereum. Set up an Ethereum Wallet
                                    to swap and provide liquidity here. Learn more</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>








        </>
    );
}