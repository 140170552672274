import React, { useEffect, useState } from "react";
import Header from "../Header";
import Card from "react-bootstrap/Card";
import { AiOutlineWarning } from "react-icons/ai";
import eth from "../../Assets/eth-svgrepo-blue.png";
import grouplog from "../../Assets/add-liguidity-group.png";
import { AiTwotoneSetting } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import blackEth from "../../Assets/eth-svgrepo-dark.png";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import Footer from "../Footer";
import Eth from "../../Assets/eth.png";
import Dai from "../../Assets/dai.png";
import Usdc from "../../Assets/usdc.svg";
import Bal from "../../Assets/Bal.svg";
import Snx from "../../Assets/snx.svg";
import { MdSettings } from 'react-icons/md';

function AddLiquidity() {

  const [coinlist, setCoinlist] = useState([
    {
      "id": 1, "image": Eth, "coinname": "WETH", "cointext": "wrapped Ether"
    },
    {
      "id": 2, "image": Dai, "coinname": "DAI", "cointext": "Dai Stablecoin"
    },
    {
      "id": 3, "image": Usdc, "coinname": "USDC", "cointext": "USD Coin"
    },
  ]);

  const [interchange, setInterchange] = useState(
    {
      "id": 1, "image": Eth, "coinname": "WETH", "cointext": "wrapped Ether"
    }
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className="add_liquidity_page innerheader">
      <Header />
      <div className="top">
        <div className="content_sec pb-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 mb-3 mb-sm-4 mb-md-0 mb-lg-0 mb-xl-0">
                <div className="my-wallet_sec">
                  <Card className="">
                    <Card.Body className="p-0">
                      <div className="d-flex justify-content-between my_wallet_sec align-items-center">
                        <p className="mb-0 my-wallet">My Wallet</p>
                        <p className="mb-0 point">
                          0.0000 ETH <AiOutlineWarning className="ms-1" />
                        </p>
                      </div>
                      <div className="main_cont">
                        <div className="d-flex">
                          <img
                            src={grouplog}
                            alt="grouplog"
                            className="img-fluid group me-4"
                          />
                          <img src={eth} alt="eth" className="img-fluid eth" />
                        </div>
                        <div>
                          <p className="mb-0 des">
                            You don’t have any token s in your connected wallet on
                            Ethereum Mainet.
                          </p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                <div className="ethereum_card_sec">
                  <Card className="">
                    <Card.Body className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className="eth_text mb-2">Ethereum</p>
                          <p className="add_liq mb-0">Add liquidity</p>
                        </div>
                        <div className="settingdrop liquidity">
                                                <Dropdown>
                                                    <Dropdown.Toggle className="setting-btn" variant="success" id="dropdown-basic">
                                                        <MdSettings className="settings" /></Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <h6 className="fw-600 mb-3">Slippage tolerance</h6>
                                                        <div class="d-flex justify-content-between">
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">0.5%</button>
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">1.0%</button>
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">2.0%</button>
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">2.5%</button>
                                                        </div>
                                                        <h6 className="fw-600 mt-4 mb-2">Transaction type</h6>
                                                        <div class="d-flex">
                                                            <button class="btn btn-outline-secondary btn-sm me-3" type="button">Legacy</button>
                                                            <button class="btn btn-outline-secondary btn-sm" type="button">Legacy</button>
                                                        </div>
                                                        <h6 className="fw-600 mt-4 mb-2">Use signature</h6>

                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                               
                                                        </div>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                        </div>
                      </div>
                      <div className=" mt-3 d-flex align-items-start commonborder liquidity  justify-content-between weth_drop_sec">
                        <div>
                          <Dropdown>
                            <Dropdown.Toggle
                              className="weth_percen_drop"
                              id="dropdown-basic"
                            >
                              <img
                                src={interchange.image}
                                alt="eth"
                                className="img-fluid eth-black-icon me-1"
                              />
                              {interchange.coinname} 20%
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {coinlist.map((e, i) =>
                                <Dropdown.Item className={e.id == interchange.id ? "hideclass" : ""} onClick={() => { setInterchange(e) }}>
                                  <div className="d-flex align-items-center">
                                    <img src={e.image} className="ethimg me-3" alt="eth" />
                                    <div>
                                      <p className="mb-0 coinname">{e.coinname}</p>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                          <p className="mb-0 mt-2 balance">Balance : 0</p>
                        </div>
                        <p className="mb-0 amt">0.0</p>
                      </div>
                      <p className="my-3 no-wallet-balance">
                        No wallet balance for some pool tokens: BAL and WETH
                      </p>
                      <div className="table_sec">
                        <Table bordered>
                          <thead>
                            <tr>
                              <th className="total_txt_th">TOTAL</th>
                              <th>$0.00</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Price impact</td>
                              <td>0.00%</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Button className="preview-btn my-3">Preview</Button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AddLiquidity;
