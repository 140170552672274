import React, { useEffect, useState } from "react";
import CoinLight from "../../Assets/ph_coins-light.png";
import DownArrow from "../../Assets/down_arrow.png";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Header from "../Header";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import Footer from "../Footer";
import Walletmodal from "../../Components/walletmodal";
import EthIcon from "../../Assets/eth.png";
import DaIcon from "../../Assets/multi-collateral-dai-dai-logo.png";
import Anchor1 from "../../Assets/anchor1.png";
import Anchor2 from "../../Assets/anchor2.png";
import { Link } from "react-router-dom";

function Portfolio() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  const [Walletmodals, setWalletmodals] = useState(false);

  const UnstackedPools = [
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "BAL 80%",
        },
        {
          val: "WETH 80%",
        },
      ],
      mybalance: "$0.000",
      poolvalue: "$127,167,635",
      apr: [
        {
          val: "0.16%",
          logo: Anchor1,
        },
      ],
      actions: "",
      type: "percentage",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "rETH",
        },
        {
          val: "WETH",
        },
      ],
      mybalance: "$0.001",
      poolvalue: "$45,189,636",
      apr: [
        {
          val: "2.70%",
          logo: Anchor2,
        },
      ],
      actions: "",
      type: "text",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "WETH",
        },
      ],
      mybalance: "$0.0000",
      poolvalue: "$22,522,768",
      apr: [
        {
          val: "3.07%",
          logo: Anchor2,
        },
      ],
      actions: "",
      type: "text",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "sfrxETH ",
        },
        {
          val: "sfrxETH ",
        },
      ],
      mybalance: "$0.000",
      poolvalue: "$22,522,768",
      apr: [
        {
          val: "3.07%",
          logo: Anchor2,
        },
      ],
      actions: "",
      type: "text",
    },
  ];
  const StackedPools = [
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "BAL 80%",
        },
        {
          val: "WETH 80%",
        },
      ],
      mybalance: "$0.000",
      poolvalue: "$127,167,635",
      apr: [
        {
          val: "0.16%",
          logo: Anchor1,
        },
      ],
      actions: "",
      type: "percentage",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "rETH",
        },
        {
          val: "WETH",
        },
      ],
      mybalance: "$0.001",
      poolvalue: "$45,189,636",
      apr: [
        {
          val: "2.70%",
          logo: Anchor2,
        },
      ],
      actions: "",
      type: "text",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "WETH",
        },
      ],
      mybalance: "$0.0000",
      poolvalue: "$22,522,768",
      apr: [
        {
          val: "3.07%",
          logo: Anchor2,
        },
      ],
      actions: "",
      type: "text",
    },
    {
      DaLogo: [
        {
          Icon: DaIcon,
        },
        {
          Icon: EthIcon,
        },
      ],
      composition: [
        {
          val: "wstETH",
        },
        {
          val: "sfrxETH ",
        },
        {
          val: "sfrxETH ",
        },
      ],
      mybalance: "$0.000",
      poolvalue: "$22,522,768",
      apr: [
        {
          val: "3.07%",
          logo: Anchor2,
        },
      ],
      actions: "",
      type: "text",
    },
  ];

  return (
    <>
      {Walletmodals && <Walletmodal onDismiss={() => setWalletmodals(false)} />}

      <div className="portfolio_page innerheader">
        <Header />
        <div className="top">
          <div className="head_sec">
            <div className="text-center">
              <h3 className="head">My Balancer Balance</h3>
              <h3 className="amount">$0.00</h3>
            </div>
          </div>
          <div className="content_sec py-5">
            <div className="container">
              <h3 className="heading">My liquidity in Balancer pools</h3>
              <div className="mt-4 mt-sm-3 mt-md-3 mt-lg-5 mt-xl-5">
                <h6 className="sub_heading mb-4">Unstaked pools</h6>
                <div className="common_table_style">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="coinlight_th">
                          <div className="coinlight_img">
                            <img
                              src={CoinLight}
                              alt="information_logo"
                              className="img-fluid info_icon"
                            />
                          </div>
                        </th>
                        <th>Composition</th>
                        <th className="my-balance">
                          My Balance <BsArrowDown />
                          {/* <BsArrowUp /> */}
                        </th>
                        <th className="pool_value">Poor Value</th>
                        <th className="apr">My APR</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {UnstackedPools.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td className="coinlight_td">
                                <div className="pools_icons_collapse">
                                  <div className="position-relative">
                                    {item.DaLogo.map((itemlogo) => {
                                      return (
                                        <>
                                          <img
                                            src={itemlogo.Icon}
                                            alt="EthIcon"
                                            className="img-fluid"
                                          />
                                        </>
                                      );
                                    })}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="composition_sec">
                                  {item.composition.map((itemm) => {
                                    return (
                                      <>
                                        <span
                                          className={
                                            item.type == "percentage"
                                              ? "high_radius"
                                              : "low_radius"
                                          }
                                        >
                                          {itemm.val}
                                        </span>
                                      </>
                                    );
                                  })}
                                </div>
                              </td>
                              <td>{item.mybalance}</td>
                              <td>{item.poolvalue}</td>
                              <td>
                                {item.apr.map((itemapr) => {
                                  return (
                                    <>
                                      {itemapr.val}
                                      <img
                                        src={itemapr.logo}
                                        alt="down_arrow"
                                        className="img-fluid info_icon ps-2"
                                      />
                                    </>
                                  );
                                })}
                              </td>
                              <td>
                                <div className="port_action_btn">
                                  <Link to="/createpool">
                                  <Button className="add_btn searchbtn">
                                    Add
                                  </Button></Link> 
                                  <Link to="/portfolioedit">
                                  <Button className="edit_btn searchbtn ms-3">
                                    Remove
                                  </Button></Link>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      {/* <tr>
                      <td
                        colSpan={6}
                        style={{ cursor: "pointer" }}
                        onClick={() => setWalletmodals(true)}
                      >
                        Connect your wallet
                      </td>
                    </tr> */}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="mt-4 mt-sm-3 mt-md-3 mt-lg-5 mt-xl-5">
                <h6 className="sub_heading mb-4">Staked pools</h6>
                <div className="common_table_style">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="coinlight_th">
                          <div className="coinlight_img">
                            <img
                              src={CoinLight}
                              alt="information_logo"
                              className="img-fluid info_icon"
                            />
                          </div>
                        </th>
                        <th>Composition</th>
                        <th className="my-balance">
                          My Balance <BsArrowDown />
                          {/* <BsArrowUp /> */}
                        </th>
                        <th className="pool_value">Poor Value</th>
                        <th className="apr">My APR</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {UnstackedPools.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td className="coinlight_td">
                                <div className="pools_icons_collapse">
                                  <div className="position-relative">
                                    {item.DaLogo.map((itemlogo) => {
                                      return (
                                        <>
                                          <img
                                            src={itemlogo.Icon}
                                            alt="EthIcon"
                                            className="img-fluid"
                                          />
                                        </>
                                      );
                                    })}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="composition_sec">
                                  {item.composition.map((itemm) => {
                                    return (
                                      <>
                                        <span
                                          className={
                                            item.type == "percentage"
                                              ? "high_radius"
                                              : "low_radius"
                                          }
                                        >
                                          {itemm.val}
                                        </span>
                                      </>
                                    );
                                  })}
                                </div>
                              </td>
                              <td>{item.mybalance}</td>
                              <td>{item.poolvalue}</td>
                              <td>
                                {item.apr.map((itemapr) => {
                                  return (
                                    <>
                                      {itemapr.val}
                                      <img
                                        src={itemapr.logo}
                                        alt="down_arrow"
                                        className="img-fluid info_icon ps-2"
                                      />
                                    </>
                                  );
                                })}
                              </td>
                              <td>
                                <div className="port_action_btn">
                                <Link to="/createpool">
                                  <Button className="add_btn searchbtn">
                                    Add
                                  </Button></Link>
                                  <Link to="/portfolioedit">
                                  <Button className="edit_btn searchbtn ms-3">
                                    Remove
                                  </Button></Link>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      {/* <tr>
                      <td
                        colSpan={6}
                        style={{ cursor: "pointer" }}
                        onClick={() => setWalletmodals(true)}
                      >
                        Connect your wallet
                      </td>
                    </tr> */}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Portfolio;
