import React, { useEffect, useState, Component } from "react";
import Chart from 'react-apexcharts'


class Apexx extends Component {

    constructor(props) {
        super(props);

        this.state = {

            series: [50, 50],
            options: {
                chart: {
                    type: 'donut',
                },
                labels: ["SNX", "AAVE"],
                colors: ["#A064A0", "#140028"],

                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: false,
                },
                tooltip: {
                    enabled: true,
                    enabledOnSeries: undefined,
                },
                legend: {
                    show: true,
                    // showForSingleSeries: false,
                    // showForNullSeries: true,
                    // showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    fontSize: '16px',
                    fontWeight: 600,
                    itemMargin: {
                        horizontal: 15,
                        vertical: 0
                    },
                },
                // responsive: [{
                //     breakpoint: 480,
                //     options: {
                //         chart: {
                //             width: 200
                //         },
                //     }
                // }],
            },


        };
        props.setStepsss({label:this.state.options.labels,colors:this.state.options.colors})
    }


    render() {
        return (
            <Chart options={this.state.options} series={this.state.series} type="donut" height={300} />
        )
    }
}

export default Apexx;
